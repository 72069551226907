/**************************************
Admin buttons
**************************************/
//@green:       #95c11f;
@dark-green:  #004417;
@green:       #36a673;
@green-light: #00a65a;
@grey-dark:   #2f3030;
@grey-light:  #a7a7a7;
@grey-lighter: #e3e3e3;
@orange: #f29400;
@red: #A60646;
@red-dark:    #be0d1d;
@white:  #ffffff;



/*********************************************************************************************************
layout
*********************************************************************************************************/
html {
  body {
    height: 100%;
    min-height: 100%;
    padding:  0px;
    margin: 0px;
    background: @grey-lighter;
    font-family: Arial, sans-serif;
    font-size: 16px;

    &, p { font-size: 14px; }

    ul {
      li { list-style-type: none; }
    }

    textarea{ resize:none }
  }
}

.form-errors {
  color: @red-dark;

  ul {
    padding: 0px;
  }
}


h1, h2, h3, h4, h5 {
  display:block;
  text-align:center;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.h1, h1 {
  font-size: 2.2rem;
}

a {
  color: @green;
}
a:hover {
  color: @dark-green;
}
a.asc:after,
a.desc:after {
  font-family: 'Font Awesome 5 Free';
  font-size: 16px;
  margin-left: 10px;
  display:inline-block;
}
a.asc:after {
  content: "\f106";
}
a.desc:after {
  content: "\f107";
}
a.site-link {
  font-size:1.8rem;
}
.border-bold{
  border:2px solid;
}
.border-grey-light {
  border-color:@grey-light;
}

ul.admin-action {
  padding: 0;
  font-size: 16px;
  display: inline-block;
}
ul.admin-action li{
  display: inline;
  margin-right: 5px;
}
ul.admin-action li a{
  color: @green;
}
ul.admin-action li a:hover{
  color: @dark-green;
}

.flex-block {
  displaY: flex;
}

.align-center {
  align-items: center;
  align-content: center;
  margin-left:auto;
  margin-right:auto;
}

.justify-center {
  justify-items: center;
  justify-content: center;
  margin-left:auto;
  margin-right:auto;
}

.pagination span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}
.list-order {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.count, .page-elements {
  padding: 10px 0;
  font-size:1rem;
}
.page-elements label {
  margin-bottom:0px;
}
//.table th {
//  width: 25%;
//}
.table-striped thead {
  background-color: #707070;
  color: @white;
}
.table-striped thead a {
  color: @white;
  text-decoration: underline;
}
.mb-1 {
  margin-bottom: 1.5rem!important;
}
.mt-1 {
  margin-top: 1.5rem!important;
}
.btn-default {
  background-color: @green;
  color: @white;
  border-color: @green;
  margin-bottom: 3px;
}
.btn-default:hover,
.btn-default:active,
.btn-default.hover {
  background-color: @dark-green;
  color: @white;
}
.enabled {
  background-color: @green;
}
.disabled {
  background-color: #707070;
}

.left-indented {
  padding-left:40px;
}

.highlight-bg {
  background-color: @grey-lighter;
}

fieldset h3 {
  margin-top:10px;
  margin-bottom:20px;
  border-bottom:1px solid @grey-light;
}

html, body {
  height: 100%;
  margin: 0;
}

/*** Header ***/
.main-header.navbar{
  background-color: @green-light;
}

.main-header.navbar .nav-link i {
  color: @white;
  &:hover{
    color: @grey-dark;
  }
}
[class*=sidebar-dark] .brand-link {
  background: #008d4c;
  text-align:center;
}


/*** End Header ***/




.wrapper {
  min-height: calc(100vh - 51px);

}
.content-wrapper {
  min-height: calc(100vh - 101px);
}
.main-footer{
  height: 51px;
}

/*** Login - Base ***/
.main-footer.full-width,
.content-wrapper.full-width
{
  margin-left:0px;
}

.content-wrapper.full-width .content-header {
  text-align:center;
}

/*******************/
/*  Status Icon    */
/*******************/

.status-active{
  color: @green;
}
td.status-active {
  background: @green;
}

.status-suspended {
  color: @orange;
}
td.status-suspended {
  background-color: @orange;
}

.status-archived {
  color: @red;
}
td.status-archived {
  background-color: @red;
}

/**********************/
/*   Input            */
/**********************/

select[readonly]{
  pointer-events: none;
}

.select2-container {
  width: 100% !important;
}
.select2-container--default .select2-selection--single{
  border-radius: 0px !important;
  height: 34px !important;
  border: 1px solid #ccc !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #222d32 !important;
  border: 1px solid #00a65a !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #fff !important;
  margin-right:5px !important;
}

/**********************/
/*  Content           */
/**********************/

/*** Title ***/

.detail-title h1,
.detail-title a {
  display:inline-block;
  vertical-align: middle;
}


/*** Login ***/

.error-msg {
  margin-top: 50px;
  background: #fff;
  border: 2px solid @red;
  padding: 10px;
  text-align: center;
}

.fos_user_resetting_request,
.login-form {
  width: 300px;
  border: 1px solid #008d4c;
  background: #fff;
  padding: 20px;
  margin: 50px auto;

}
.fos_user_resetting_request legend,
.login-form legend {
  border-bottom: none;
  text-align: center;
  background: #008d4c;
  color: #fff;
  padding: 5px;
}
.fos_user_resetting_request label,
.login-form label {
  margin-top: 10px;
  color:#fff;
  color:#008d4c;
}

.fos_user_resetting_request input[type="text"],
.fos_user_resetting_request input[type="password"],
.fos_user_resetting_request input[type="submit"],
.login-form input[type="text"],
.login-form input[type="password"],
.login-form input[type="submit"],
.login-form .btn
{
  width:100%;
  display:block;
}

.fos_user_resetting_request input[type="submit"],
.login-form input[type="submit"],
.login-form .btn{
  background: #008d4c;
  color:#fff;
  padding: 10px;
  margin-top: 20px;
  margin-bottom:10px;
}


/*** Form ***/
label[for='service_payments_filter_hideSent']{
  padding:20px 0px 20px 30px;
  font-weight:bold;
}

input#service_payments_filter_hideSent {
  height: 20px;
  width: 20px;
  margin:0 0 0 -30px;
}

/*********************/
/*    Responsive     */
/*********************/

@media(max-width:979px){
  .table th {
    width: 35%;
  }
}

@media(max-width:778px){

  //.table th {
  //  width: unset;
  //}

  table, thead, tbody, th, td, tr {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr { border: 1px solid #ccc; }
  td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 200px;
    margin-left: 150px;
  }

  td:before {
    position: absolute;
    top: 8px;
    left: 6px;
    width: 200px;
    padding-right: 40px;
    white-space: nowrap;
    margin-left: -150px;
    content:attr(data-content);
  }

}