/**
 * AdminLTE & plugins
 */
@import "~admin-lte/dist/css/adminlte.min.css";
@import "~admin-lte/plugins/fontawesome-free/css/all.min.css";
//@import "~admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css";
@import "~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css";
@import "~admin-lte/plugins/jqvmap/jqvmap.min.css";
@import "~admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css";
@import "~admin-lte/plugins/daterangepicker/daterangepicker.css";
@import "~admin-lte/plugins/summernote/summernote-bs4.min.css";

/**
 * other vendors
 */
@import "~select2/dist/css/select2.min.css";
@import "~icheck/skins/all.css";

/**
 * my styles
 */
@import "layout";




